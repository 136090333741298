/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Button, Spinner } from "components/Elements";
import { Notifications } from "components/Notifications/Notifications";
import { AuthProvider } from "lib/auth";
import { queryClient } from "lib/react-query";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AlertOctagon } from "react-feather";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

const ErrorFallback = () => {
  useEffect(() => {
    // clear localstorage on error
    localStorage.clear();
  });
  return (
    <div
      className="text-gray-400 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <AlertOctagon size="20" />
      <h2 className="text-lg font-semibold">Something went wrong. </h2>
      <br />
      <h2 className="text-xs font-semibold">
        It's probably a minor error which has been reported. Contact support if
        issue persists.
      </h2>
      <Button
        className="mt-4"
        onClick={() => {
          localStorage.removeItem("msw-dbtoken");
          window.location.assign(window.location.origin);
        }}
      >
        Refresh!
      </Button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
