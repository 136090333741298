import logo from "assets/logo.png";
import { useAuth } from "lib/auth";
import { useAuthorization, ROLES } from "lib/authorization";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  UserIcon,
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  InformationCircleIcon,
  MailOpenIcon,
  FilmIcon,
  CameraIcon,
  ShieldCheckIcon,
  ArrowCircleUpIcon,
} from "@heroicons/react/outline";
import {
  LightBulbIcon,
  UserGroupIcon,
  ViewListIcon,
  // ShieldCheckIcon,
} from "@heroicons/react/solid";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import {
  FileText,
  Bookmark,
  File,
  List,
  Briefcase,
  LogOut,
  Settings,
} from "react-feather";
import { NavLink, Link } from "react-router-dom";

const SideNavigation = () => {
  const { checkAccess } = useAuthorization();
  const navigation = [
    { name: "Dashboard", to: ".", icon: HomeIcon },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Blog Posts",
      to: "./blog",
      icon: FileText,
      heading: "Content",
    },
    checkAccess({ allowedRoles: [ROLES.PR, ROLES.ADMIN, ROLES.MARKETING] }) && {
      name: "Banner Adverts",
      to: "./ads",
      icon: Bookmark,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Notices",
      to: "./notices",
      icon: List,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Resources",
      to: "./resources",
      icon: File,
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Gallery",
      to: "./gallery",
      icon: CameraIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Services",
      to: "./services",
      icon: ShieldCheckIcon,
    },
    // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
    //   name: "Multimedia",
    //   to: "./multimedia",
    //   icon: FilmIcon,
    // },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Pages",
      to: "./pages",
      icon: File,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Faqs",
      to: "./faqs",
      icon: InformationCircleIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Designations",
      to: "./designations",
      icon: File,
      heading: "Governance",
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Directorates",
      to: "./directorates",
      icon: Briefcase,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Managers",
      to: "./managers",
      icon: UsersIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Governing Council",
      to: "./governing-council",
      icon: UserGroupIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Management",
      to: "./management",
      icon: ArrowCircleUpIcon,
    },
    checkAccess({ allowedRoles: [ROLES.HR, ROLES.ADMIN] }) && {
      name: "Careers",
      to: "./careers",
      icon: FolderIcon,
      heading: "Human Resources",
    },
    checkAccess({ allowedRoles: [ROLES.HR, ROLES.ADMIN] }) && {
      name: "Job Applications",
      to: "./applications",
      icon: ViewListIcon,
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Mailing List",
      to: "./mailing-list",
      icon: MailOpenIcon,
      heading: "Other",
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: "Users",
      to: "./users",
      icon: UsersIcon,
    },
    checkAccess({ allowedRoles: [ROLES.DEVELOPER] }) && {
      name: "Suggestions",
      to: "./changes/suggestions",
      icon: LightBulbIcon,
    },
  ].filter(Boolean);

  return (
    <>
      {navigation.map((item, index) => (
        <div>
          {item.heading && (
            <p
              key={index}
              className="px-3 py-4 my-4 border-t-2 border-b-2 text-xs font-semibold text-gray-400 uppercase tracking-wider"
            >
              {item.heading}
            </p>
          )}
          <NavLink
            end={index === 0}
            key={item.name}
            to={item.to}
            className={clsx(
              "text-gray-800  hover:text-gray-300",
              "group flex items-center px-2 py-2 text-base font-medium rounded-md "
            )}
            activeClassName="bg-black"
          >
            <div className="flex flex-col">
              {/* <p className=" bg-gray-300 font-extrabold ">{item.heading}</p> */}

              <div className="flex">
                <item.icon
                  className={clsx(
                    "text-gray-800 group-hover:text-gray-300",
                    "mr-4 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  );
};

const UserNavigation = () => {
  const { logout } = useAuth();

  const userNavigation = [
    {
      name: "Your Profile",
      icon: <UserIcon className="h-4" />,
      to: "./profile",
    },
    {
      name: "Changelog",
      icon: <LightBulbIcon className="h-4" />,
      to: "./changes",
    },
    { name: "Settings", icon: <Settings className="h-4" />, to: "./settings" },
    {
      name: "Sign out",
      icon: <LogOut className="h-4" />,
      to: "",
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean);

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs  bg-white border border-gray-200 p-2 flex items-center text-sm rounded-full focus:none">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-4 w-4 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 dark:bg-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        `flex flex-row items-center`,
                        active ? "bg-gray-100 dark:bg-gray-700" : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-400"
                      )}
                    >
                      {item.icon}
                      <span className="ml-2">{item.name}</span>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

MobileSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};
const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0 border">
      <div className="flex flex-col w-60 shadow-md">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4 dark:bg-gray-900">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-white dark:bg-gray-900 shadow-md space-y-1">
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to=".">
      <img className="h-10 w-auto" src={logo} alt="Workflow" />
    </Link>
  );
};

export const MainLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 items-center flex-shrink-0 flex h-16 bg-white dark:bg-gray-800 shadow">
          <div className="px-10 text-xs text-gray-300">
            © Copyright 2022 - National Curriculum Development Centre © 2022,{" "}
            <a href="https://jast-tech.com" target="_blank" rel="noreferrer">
              Jast Tech Systems
            </a>
          </div>
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none dark:bg-gray-800">
          {children}
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
